@media screen and (max-width: 500px) {
    .css-181cdy9 {
      display: none;
    }

    .css-1yezka2-MuiTypography-root{
        font-size: 21px !important;
    }

    .css-bz1ix6{
        width: 94vw;
    }

    .css-4513st{
        font-size: 1.3rem;
        font-family: 'Urbanist-Medium';
    }
    .css-bz1ix6 {
        margin-top: 23px !important;
    }

     .css-qkwlfp {
        margin-right: 13px !important;
    }
    .css-lbbh85{
        display: flex;
        flex-direction: column;
        margin-top: 13px !important;
        margin-bottom: 13px !important;
    }

   .css-bv6fd0-MuiButtonBase-root-MuiButton-root{
        width: 87vw !important;
        margin-bottom: 10px !important;
    }

     .css-1vpstr-MuiTypography-root{
        width: 80vw;
    }

     /* .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root{
        width: 87vw !important;
    } */
    .css-4nb6ss{
        display: flex;
        justify-content: flex-start !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        width: 87vw;
    }

   .css-1jivpc7-MuiButtonBase-root-MuiButton-root{
        font-size: 1.8rem !important;
        padding: 1rem;
    }

     .css-162b50h-MuiCardActions-root{
        width: 94vw !important;
    }

    /* .css-1n2mv2k{
        width: 20rem !important;
    } */
    .css-16dv9uf-MuiButtonBase-root-MuiButton-root {
        margin-bottom: 10px !important;
    }

    .css-3khax1-MuiButtonBase-root-MuiButton-root{
        min-width: 84vw !important;
    }
    /* cart */
    /* .css-16to7xx{
        display: none !important;
    } */
  }

  @media screen and (min-width: 500px) and (max-width: 919px) {

     .css-181cdy9{
        height: 105vh !important;
        object-fit: cover;
    }
 
     .css-4513st{
        font-size: 20px;
    }
 .css-1yezka2-MuiTypography-root{
        font-size: 25px !important;
    }

    .css-bv6fd0-MuiButtonBase-root-MuiButton-root{
        margin-right: 1rem !important;
    }

     .css-12keydw{
        padding-bottom: 40px !important;
    }

     .css-lbbh85{
        display: flex;
        flex-direction: column;
       
    }

    .css-12keydw{
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .css-bv6fd0-MuiButtonBase-root-MuiButton-root {
        margin-bottom: 15px !important;
    }
    /* .css-1n2mv2k{
        width: 20rem !important;
    } */
    .css-16dv9uf-MuiButtonBase-root-MuiButton-root {
        margin-bottom: 10px !important;
    }
     /* cart */
     /* .css-16to7xx{
        display: none !important;
    } */
  }

  @media screen and (max-width: 920px) {
     .css-181cdy9{
        height: 71.5vh;
        object-fit: cover;

    }

     .css-1gck903{
        margin-left: 10rem !important;
    }

  }

  @media screen and (min-width: 921px) and (max-width: 1200px) {
     .css-181cdy9{
        height: 148vh;
        object-fit: cover;
    }

    /* #signinPage .css-lbbh85{
        display: flex;
        flex-direction: row !important;
       
    } */

     .css-1gck903{
        margin-left: 13rem !important;
    }

    .css-lbbh85 {
        flex-direction: column !important;
    }

    /* .css-1n2mv2k{
        width: 20rem !important;
    } */
    .css-16dv9uf-MuiButtonBase-root-MuiButton-root {
        margin-bottom: 10px !important;
    }

    /* .css-16to7xx{
        display: none !important;
    } */

  }

  @media screen and (min-width: 1200px) {
      .css-lbbh85{
        display: flex;
        flex-direction: column !important;
       
    }

    /* .css-1n2mv2k{
        width: 26rem !important;
    }
    .css-16dv9uf-MuiButtonBase-root-MuiButton-root {
        margin-bottom: 10px !important;
    } */
/*     
    .css-16to7xx{
        display: none !important;
    } */
  }
  .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 6.5px 14px !important;
  }