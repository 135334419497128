@import url(https://rsms.me/inter/inter.css);
@font-face {
  font-family: "Urbanist-Black";
  src: url(/static/media/Urbanist-Black.d72623c1.ttf);
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Urbanist-BlackItalic";
  src: url(/static/media/Urbanist-BlackItalic.36a91e6d.ttf);
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Urbanist-SemiBold";
  src: url(/static/media/Urbanist-SemiBold.7da44a6d.ttf);
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Urbanist-Bold";
  src: url(/static/media/Urbanist-Bold.578c7c1d.ttf);
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Urbanist-BoldItalic";
  src: url(/static/media/Urbanist-BoldItalic.4f34d0ae.ttf);
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Urbanist-ExtraBold";
  src: url(/static/media/Urbanist-ExtraBold.5513c4b5.ttf);
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Urbanist-ExtraBoldItalic";
  src: url(/static/media/Urbanist-ExtraBoldItalic.14ccab15.ttf);
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Urbanist-Italic";
  src: url(/static/media/Urbanist-Italic.74c72951.ttf);
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Urbanist-Light";
  src: url(/static/media/Urbanist-Light.55ed2929.ttf);
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Urbanist-LightItalic";
  src: url(/static/media/Urbanist-LightItalic.81ad57c6.ttf);
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Urbanist-Medium";
  src: url(/static/media/Urbanist-Medium.934caf89.ttf);
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Urbanist-MediumItalic";
  src: url(/static/media/Urbanist-MediumItalic.608c8af2.ttf);
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Urbanist-Regular";
  src: url(/static/media/Urbanist-Regular.e7543705.ttf);
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Urbanist-Thin";
  src: url(/static/media/Urbanist-Thin.16cdeb2a.ttf);
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Urbanist-ThinItalic";
  src: url(/static/media/Urbanist-ThinItalic.d6faa2a7.ttf);
  font-style: normal;
  font-display: block;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Urbanist-Regular', 'Urbanist-Thin', 'Urbanist-Medium',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: '0px !important';

}

a {
  text-decoration: none;
}

/* Will Make Header Sticky on Scroll */
.is-sticky {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  z-index: 999 !important;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%) !important;
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

/* .oneGlint-link-title {
  color: red;
} */


.oneGlint-active-link-title {
  color: #ddc970 !important;
}

.oneGlint-active-link-title::after {
  content: '';
  width: 100%;
  height: 5px;
  background-color: #ddc970;
}

.user-menu-list:last-child {
  border: none;
}

/* css-1te3qol-MuiGrid-root */

@media (min-width: 1800px) {
  .css-1te3qol-MuiGrid-root li {
    width: 8rem !important;
  }
}


.MuiCheckbox-colorPrimary.Mui-checked {
  color: #3f0e77 !important;
}

@media(max-width: 500px) {

  /* custom navbar */
  .css-l9him9-MuiPaper-root-MuiAppBar-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }



  /* summary section */
  .css-1aeewgg-MuiTypography-root {
    padding-right: 42px !important;
    padding-left: 48px !important;
    border-bottom: 1px solid lightgrey;
    width: 124% !important;
    margin-bottom: 13px !important;
  }

  .css-1aeewgg-MuiTypography-root span {
    margin-bottom: 12px;
  }

  .premiumModal {
    height: 30%;
    overflow-x: scroll;
  }
}

.css-mhc70k-MuiGrid-root {
  display: flex;
  justify-content: center;
}

.css-1jssz31-MuiGrid-root {
  flex-basis: 0% !important;
}

.css-o92l7v-MuiButtonBase-root-MuiButton-root {
  margin-right: 85px !important;
}

.css-182didf.MuiSelect-select {
  display: flex !important;
  align-items: center !important;
  height: "2.4rem" !important;
}

.collageWarning {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  grid-gap: 4px;
  gap: 4px;

  width: 415px;
  height: 48px;


  background: #FEE4E2;
  border-radius: 8px;
  margin-bottom: 15px;
}

.collageWarningText {
  color: #F04438;
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 100vw;
}

/**custom css 06.06.23**/
/* @media(max-width:899px){ */
/* .css-1e9f679, .css-iarih6-MuiGrid-root{ */
/* .css-1e9f679 .web_view{
    display: none !important;
  }
} */
@media(max-width:428px) {
  .css-basgfo {
    padding: 10px 50px !important;
    height: auto !important;
  }
}

@media (max-width:500px) {
  .css-ugb9rx {
    width: 350px !important
  }
}

.css-1e9f679 {
  background-color: #e4e4e4 !important;
}

.css-qkwlfp svg {
  vertical-align: middle;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 6px 14px !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: -7px !important;
}



/**For Loader**/
.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
  left: 48%;
  margin-top: 15%;
}

.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #4f0070;
  animation: prixClipFix 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg)
  }
}

@keyframes prixClipFix {
  0% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
            clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
  }

  25% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
  }

  50% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
  }

  75% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%)
  }

  100% {
    -webkit-clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
            clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0)
  }
}


/**Today 29.08.23**/
.EventRegdOl {
  padding: 10px 10px 10px 20px;
}

.EventRegdOl li {
  padding-bottom: 10px;
}

.termCondition {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
}


.container {
  width: 80%;
  margin: 50px auto;
}

.button {
  background: #2196f3;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
}

.button:hover {
  background: #0b7dda;
}

/* Style the modal */
.modal {
  position: fixed;
  /* Stay in place */
  z-index: 9999;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  background-color: rgba(0, 0, 0, 0.4);
  /* Semi-transparent black background */

}

/* Modal Content */
.modal-content {
  margin: auto;
  padding: 20px;
  display: flex;
  align-items: start;
}

.modal-text {
  font-size: 18px;
  color: #ccc;
}

/* Create the circle loader with pure CSS */
.loader {
  width: 50px;
  height: 50px;
  border: 8px solid #ddc970;
  border-top: 8px solid #3f0e77;
  border-bottom: 8px solid #3f0e77;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.datepicker_custom input {
  width: 100%;
  /* height: 1.4375em; */
  border-radius: 3px;
  border: 1px solid #ccc;
  padding: 10px 14px;
}

.datepicker_custom .react-datepicker-wrapper {
  display: block;
}

.container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checkbox-input {
  margin-right: 10px;
}

.checkbox-label {
  font-size: 16px;
  cursor: pointer;
}

.checkbox-label:hover {
  text-decoration: underline;
}



/* .fullWidthContainer{
  max-width: 1150px !important;
  width: 100% !important;
  margin: 0 auto !important;
  padding: 0 20px;
} */

/* .fullWidthContainer .custom_table th{
  padding: 10px;
  width: 60% !important;
  line-height: 10;
} */

/**MediaQuery**/
@media(max-width:1300px) {}

@media(max-width:575px) {
  .evntRegd {
    margin-left: 10px !important;
  }
}

@media (max-width:599px) {
  .GridpaddingRight {
    padding-right: 0 !important;
  }
}

/* pagination */
@media screen and (max-width: 375px) {
  .css-jx5j6l .MuiPagination-ul {
    grid-gap: 7px !important;
    gap: 7px !important;
  }
}

@media screen and (min-width: 375px) {
  .css-jx5j6l .MuiPagination-ul {
    grid-gap: 12px !important;
    gap: 12px !important;
  }
}



.css-jx5j6l .MuiPagination-ul {
  margin: 16px 4px !important;
}

.dropdown {
  position: relative;
  display: inline-block;

}

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  padding: 10px;
  z-index: 9;
  max-Height: 200px;
  overflow-y: auto
}

/* .dropdown:hover .dropdown-content {
  display: block;
} */

/* Additional styling for the input and card content */
#myInput {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.card {
  padding: 10px;
  background-color: white;
  border-radius: 4px;
  margin-top: 5px;
  cursor: pointer;
}
@media(max-width:600px) {
    #combo-box-demo {
        color: white;
        font-family: 'Urbanist-Regular';
        height: 2.5rem;
        width: 100%;
        font-size: 1.5rem;
    }

    #afterSubmit {
        color: black;
        font-family: 'Urbanist-Regular';
        height: 2.5rem;
        font-size: 1.3rem;
    }

    #combo-box-demo::placeholder {
        color: white
    }
}

@media(max-width:1546px) {
    #combo-box-demo {
        color: white;
        font-family: 'Urbanist-Regular';
        height: 2.5rem;
        width: 100%;
        font-size: 16px;
    }

    #afterSubmit {
        color: black;
        font-family: 'Urbanist-Regular';
        /* height: 2.5rem; */
        font-size: 16px;
    }

    #combo-box-demo::placeholder {
        color: white
    }
}

@media (max-width:1900px) {
    #combo-box-demo {
        color: white;
        font-family: 'Urbanist-Regular';
        height: 40px;
    }

    #afterSubmit {
        color: black;
        font-family: 'Urbanist-Regular';
    }

    #combo-box-demo::placeholder {
        color: white
    }
}

.MuiAutocomplete-root {
    border-radius: 5px;
}

#afterSubmit {
    color: black;
}
:root {
  --color-light: white;
  --color-dark: #000000;
  --color-signal: #0B4AAF   ;
  --color-background: var(--color-light);
  --color-text: var(--color-dark);
  --color-accent: var(--color-signal);
  --size-bezel: .5rem;
  --size-radius: 4px;
  line-height: 1.4;
  font-family: "Inter", sans-serif;
  font-size: calc(0.6rem + 0.4vw);
  color: var(--color-text);
  background: var(--color-background);
} 

.input {
  position: relative;
}
.input__field {
  margin-top: 0.4rem;
  box-sizing: border-box;
  display: block;
  width: 100%;
  border: 1px solid currentColor;
  padding: calc(var(--size-bezel) * 1.5) var(--size-bezel);
  color: currentColor;
  background: transparent;
  border-radius: var(--size-radius);
}
.input__field:focus + .input__label, .input__field:not(:placeholder-shown) + .input__label {
  color: var(--color-accent);
}

.hidden {
  display: none;
}

.modalDiv{
  overflow-x: hidden !important;
}
.newbox {
    background-color: #3F0E77;
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.lefttext {
    color: #ffffff;
}

.advancedSearchField{
font-style: normal;
font-weight: 500;
font-size: 14px;
width: 14rem;
color: #D0D5DD;
}
.advancedSearchField:hover{
    border: none;
}
.thead {
    color: #3F0E77 !important;
    font-size: 16px !important;
    font-weight: bold !important;
}

.tablerow {
    background-color: #ffffff !important;
}

.newboxab {
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.newboxc {
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


@media (min-width: 900px) and (max-width: 1300px) {
    .heroImageAlignInput,.css-1m58let {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}
img {
  max-width: 100%;
  display: block;
}

figure {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 10px;
  page-break-inside: avoid;
  break-inside: avoid;
}

figure > img {
  grid-row: 1 / -1;
  grid-column: 1;
}

figure a {
  color: black;
  text-decoration: none;
}

figure:hover{
  /* display: block; */
  cursor:pointer;
}


figcaption {
  grid-row: 2;
  grid-column: 1;
  /* background-color: rgba(255,255,255,.5); */
  cursor: pointer;
  padding: .2em .5em;
  justify-self: center;
  margin-bottom: 2rem;
}

/* .hide{
  display: none;
}
figure:hover + .hide{
  display: flex;
  border: 5px solid red;
} */



.container {
  column-count: 4;
  grid-column-gap: 10px;
  column-gap: 10px;
}

/* .container:before {
content:"";
position:absolute;
width:100%;
height:100%;
top:0;left:0;right:0;
background-color:rgba(0,0,0,0);
} */
/* .container:hover::before {
background-color:rgba(0,0,0,0.5);
} */
/* .container img {
display:block;
}
.container button {
position: absolute;
top: 60%;
left: 50%;
transform: translate(-50%, -50%);
-ms-transform: translate(-50%, -50%);
opacity:0;
}  */
/* .container:hover button {   
opacity: 1;
} */

@media(max-width: 500px){

.container {
width: 72% !important;
margin-left: 56px !important;
column-count: auto !important;
grid-column-gap: 0px !important;
column-gap: 0px !important;
}
}
.css-1ndpvdd-MuiTableCell-root {
    color: #3F0E77 !important;

}


.css-quj9j6-MuiTable-root {
    background-color: #f2f6ff !important;

}

.css-1q1u3t4-MuiTableRow-root {

    background-color: #f4ebff;
}

.css-1lxrn4q {
    padding-top: 0% !important;
}


.leftgrid {
    display: flex;
    flex-direction: row;
}

.bgbox {
    width: 100%;
    height: auto;
    background: #F4EBFF;
    padding-bottom: 0% !important;
}


.imagecontent {
    margin: 10px;
}
/* Less than or equal to 500px */
@media (max-width: 500px) {
    /* Modal */
    .css-10je69f {
        width: 245px !important;
    }

    .css-cohsu9 {
        width: 15vw !important;
    }

    .css-1lmnfor {
        display: flex;
        flex-wrap: unset !important;
        grid-gap: 0 !important;
        gap: 0 !important;
        margin-bottom: 20px;
    }
    
    .css-rcwncx {
        font-size: 13px !important;
    }

    .css-19f6m5p-MuiButtonBase-root-MuiButton-root {
        padding-top: 12px !important;
        padding-bottom: 12px !important;
        font-size: 13px !important;
    }

    .css-1rfvejp-MuiTypography-root{
        display: flex;
        justify-content: center !important;
    }

    .css-15dpfag{
        display: block !important;
    }

    /* .css-12rf6b2 {
        display: block !important;
    } */
    .css-12rf6b2:after {
       content:"Checkout";
       font-size: 14px;
    }
}
.my-masonry-grid {
    /* Not needed if autoprefixing */
    /* Not needed if autoprefixing */
    display: flex;
    margin-left: -8px;
    /*gutter size offset*/
    width: auto;
    grid-gap: 10px;
    gap: 10px;
}

.my-masonry-grid_column {
    padding-left: 8px;
    /* gutter size */
    background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column>div {
    /* change div to reference your elements you put in <Masonry> */
    /* background: grey; */
    margin-bottom: 8px;

}



.overlay {
    max-width: "19rem";
    height: "12rem";
    position: "relative";
    top: '50%';
    background:
        "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,212,255,0) 75%, rgba(0,212,255,0) 100%)";
    opacity: 0;
}




.closeround {
    background-color: red;
    border-radius: 50%;
    color: #fff;
    height: 18px;
    width: 18px;
}

.muimodalfirst {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.cardmodal {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: solid 1px black;
    width: 220px;
    border-radius: 3px;
    z-index: 3px;
    margin: 10px;
    padding: 10px;
}

@media only screen and (max-width: 600px) {
    .cardmodal {
        border: solid 1px black;
        width: 156px;
        border-radius: 3px;
        z-index: 3px;
        margin: 10px;
        padding: 10px;
    }

}

.cardimgboxone {
    width: 100%;
    object-fit: cover;
    height: 107px;
    border-radius: 3px;
}

.css-gnmkce marquee{
    margin-top: 3rem;
}

/* product modal */


.css-1t3ij9a-MuiGrid-root{
    display: flex;
    justify-content: center;
    align-items: center;
}


/* .css-mhc70k-MuiGrid-root {
    justify-content: center !important;
} */

/* .css-1jssz31-MuiGrid-root {
    -webkit-flex-basis: 0% !important;
} */

/* .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    margin-top: -45px !important;
    margin-right: -41px !important;
} */

.css-grmm1u-MuiButtonBase-root-MuiButton-root{

    margin-top: -57px !important;
        margin-right: -57px !important;
}


@media only screen and (max-width: 600px) {
    .cardimgboxone {
        width: 100%;
        height: 160px;
        border-radius: 3px;
    }

    .imgboxpic {
        width: 100% !important;
        /* margin-left: 22px !important; */
        object-fit: cover;
    }
}


.smallname {
    font-size: 14px;
    color: #6B7280;

}

.pricename {
    color: #000;
    font-weight: bolder;
}

.buttonsmodal {
    background-color: #E5E7EB;
    margin-top: 10px !important;
    padding: 25px;
    padding-right: 38px;
    transform: translateX(12px);
    border-radius: 3px;

}

.picmodal {
    /* border: 1px solid #E5E7EB; */
    border-radius: 5px;
}


.imgboxpic {
    height: 24rem;
    width: 100%;
    object-fit: contain;
    /* padding: 21px; */
    border-radius: 5px;
    position: relative;
    background-repeat: no-repeat;
    background-size: contain;
}

.react-multiple-carousel__arrow {
    min-height: 30px;
    min-width: 30px;
    /* margin-bottom: 10rem; */
}

.css-a5625r,.css-fwhstj {
    /* height: 93vh; */
    width: 39vw !important;
}

/* .css-1hkylpt {
    height: 93vh;
} */

@media only screen and (max-width: 600px) {

    .react-multiple-carousel__arrow {
        display: none;
    }
}

.react-multiple-carousel__arrow--left {

    background-color: #3f0e77;
    font-size: 13px !important;

}

.react-multiple-carousel__arrow--right {

    background-color: #3f0e77;
    font-size: 13px !important;


}


.closeroundnew {
    background-color: red;
    right: 0% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
    border-radius: 50%;
    color: #fff;
    height: 18px;
    width: 18px;
}

@media only screen and (max-width: 600px) {
    .closeroundnew {
        height: 13px;
        width: 13px;
    }
}


.pickclickbox {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.leftpickpara {
    line-height: 16px !important;
    font-size: 0.8rem !important;
    width: 100%;
    color: #6B7280;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.cardmodalnew {
    border: solid 1px #D1D5DB;
    width: 180px;
    border-radius: 3px;
    z-index: 3px;
    margin: 10px;
    padding: 10px;
}


.cardimgboxonenew {
    width: 155px;
    height: 107px;
    border-radius: 3px;
}

.css-1nios73-MuiButtonBase-root-MuiButton-root {
    width: 156px !important;
}

.buttonsmodalnew {
    background-color: #E5E7EB;
    margin-top: 5px !important;
    padding: 10px;
    padding-right: px;
    transform: translateX(12px);
    border-radius: 3px;

}

.css-grmm1u-MuiButtonBase-root-MuiButton-root {
    margin-right: -57px;
    margin-top: -57px;
}



/* css for hovereff */

/* 
.container {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 40px 0;
}

.container .card {
    position: relative;
    width: 300px;
    height: 400px;
    background: rgba(255, 255, 255, 0.05);
    margin: 20px;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
}

.container .card .content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: 0.5s;
}

.container .card:hover .content {
    transform: translateY(-20px);
}

.container .card .content .imgBx {
    position: relative;
    width: 150px;
    height: 150px;
    overflow: hidden;
}

.container .card .content .imgBx img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
} */




/* //new */
.masonimage {
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.sci {
    position: absolute;
    /* bottom: 8px; */
    background-color: #3f0e77;
    flex-wrap: wrap;
    width: 100%;
    z-index: 3;

}



.masonimage .sci li {
    list-style: none;
    margin: 0 10px !important;
    transition: 0.5s;
    background-color: #3f0e77;
    width: 147px;
    display: flex;
    flex-direction: row;
    opacity: 1;
    display: none;
    height: 65px;
    align-items: center;


}

@media only screen and (max-width: 600px) {

    .sci.hover {
        display: none;
    }
    
    .sci{
        display: flex !important;
        flex-wrap: unset !important;
    }

    .css-1sexnva-MuiSvgIcon-root {
        margin-bottom: -3px !important;
    }

    .css-hbwkp5-MuiGrid-root {
        max-width: 100% !important;
    }

    .css-hbwkp5-MuiGrid-root button{
        width: 100% !important;
    }

    .css-1krj0ox-MuiButtonBase-root-MuiButton-root {
        width: 98% !important;
    }
}


.masonimage:hover .sci li {
    list-style: none;
    margin: 0 10px !important;
    transition: 0.5s;
    background-color: #3f0e77;
    width: 147px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    opacity: 1;
    z-index: 3;
    /* transform: translateY(-70px); */

}

@media only screen and (max-width: 600px) {

    .masonimage .sci li {
        list-style: none;
        transition: 0.5s;
        background-color: #3f0e77;
        height: 60px;
        justify-content: space-between;
        display: contents;
        flex-wrap: wrap;
        opacity: 1;
        z-index: 3;
        text-align: center;



    }
}

@media only screen and (min-width:1920px) {

    .masonimage:hover .sci li {
        list-style: none;
        margin: 0 10px !important;
        transition: 0.5s;
        background-color: #3f0e77;
        width: 30%;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        opacity: 1;
        z-index: 3;
        text-align: center;



    }
}
.my-masonry-grid {
    /* Not needed if autoprefixing */
    /* Not needed if autoprefixing */
    display: flex;
    margin-left: -8px;
    /*gutter size offset*/
    width: auto;
    grid-gap: 10px;
    gap: 10px;
}

.my-masonry-grid_column {
    padding-left: 8px;
    /* gutter size */
    background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column>div {
    /* change div to reference your elements you put in <Masonry> */
    /* background: grey; */
    margin-bottom: 8px;

}



.overlay {
    max-width: "19rem";
    height: "12rem";
    position: "relative";
    top: '50%';
    background:
        "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,212,255,0) 75%, rgba(0,212,255,0) 100%)";
    opacity: 0;
}




.closeround {
    background-color: red;
    border-radius: 50%;
    color: #fff;
    height: 18px;
    width: 18px;
}

.muimodalfirst {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.cardmodal {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: solid 1px #D1D5DB;
    width: 220px;
    border-radius: 3px;
    z-index: 3px;
    margin: 10px;
    padding: 10px;
}

@media only screen and (max-width: 600px) {
    .cardmodal {
        border: solid 1px #D1D5DB;
        width: 156px;
        border-radius: 3px;
        z-index: 3px;
        margin: 10px;
        padding: 10px;
    }

}

.cardimgboxone {
    width: 100%;
    object-fit: cover;
    height: 107px;
    border-radius: 3px;
}

.css-gnmkce marquee{
    margin-top: 0rem;
}

/* product modal */


.css-1t3ij9a-MuiGrid-root{
    display: flex;
    justify-content: center;
    align-items: center;
}


/* .css-mhc70k-MuiGrid-root {
    justify-content: center !important;
} */

/* .css-1jssz31-MuiGrid-root {
    -webkit-flex-basis: 0% !important;
} */

/* .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    margin-top: -45px !important;
    margin-right: -41px !important;
} */

.picmodal>button{

    margin-top: -57px !important;
        margin-right: -57px !important;
}


@media only screen and (max-width: 600px) {
    .cardimgboxone {
        width: 100%;
        height: 55px;
        border-radius: 3px;
    }

    .imgboxpic {
        width: 100% !important;
        /* margin-left: 22px !important; */
        object-fit: cover;
    }
}
.cartButton{
    width: 150px !important;
}

.buttonContainer{
    display: flex !important;
    flex-direction: row !important;
    grid-column-gap: 0 !important;
    column-gap: 0 !important;
}
.smallname {
    font-size: 14px;
    color: #6B7280;
}

.pricename {
    color: #000;
    font-weight: bolder;
}

.buttonsmodal {
    background-color: #E5E7EB;
    margin-top: 10px !important;
    padding: 25px;
    padding-right: 38px;
    transform: translateX(12px);
    border-radius: 3px;

}

.picmodal {
    border: 1px solid #E5E7EB;
    border-radius: 5px;
}


.imgboxpic {
    height: 24rem;
    width: 100%;
    object-fit: contain;
    /* padding: 21px; */
    border-radius: 5px;
    position: relative;
    background-repeat: no-repeat;
    background-size: contain;
}

.react-multiple-carousel__arrow {
    min-height: 30px;
    min-width: 30px;
    /* margin-bottom: 10rem; */
}

.css-a5625r,.css-fwhstj {
    /* height: 93vh; */
    width: 39vw !important;
}

/* .css-1hkylpt {
    height: 93vh;
} */

@media only screen and (max-width: 600px) {

    .react-multiple-carousel__arrow {
        display: none;
    }
}

.react-multiple-carousel__arrow--left {

    background-color: #3f0e77;
    font-size: 13px !important;

}

.react-multiple-carousel__arrow--right {

    background-color: #3f0e77;
    font-size: 13px !important;


}


.closeroundnew {
    background-color: red;
    right: 0% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
    border-radius: 50%;
    color: #fff;
    height: 18px;
    width: 18px;
}

.picmodal>button{
    margin-right: -57px !important;
    margin-top: -57px !important;
}

@media only screen and (max-width: 600px) {
    .closeroundnew {
        height: 13px;
        width: 13px;
    }
}


.pickclickbox {
    display: flex;
    justify-content: space-around;
}

.leftpickpara {
    padding: 10px;
    line-height: 16px !important;
    font-size: 0.8rem !important;
    width: 100%;
    color: #6B7280;
    margin-left: 20px !important;
    padding-right: 40px !important;
}

.cardmodalnew {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: solid 1px #D1D5DB;
    width: 90%;
    border-radius: 3px;
    z-index: 3px;
    margin: 10px;
    padding: 10px;
}


.cardimgboxonenew {
    width: 100%;
    height: 107px;
    border-radius: 3px;
    object-fit: contain;
}

.css-1nios73-MuiButtonBase-root-MuiButton-root {
    width: 156px !important;
}

.buttonsmodalnew {
    background-color: #E5E7EB;
    margin-top: 5px !important;
    padding: 10px;
    padding-right: px;
    transform: translateX(12px);
    border-radius: 3px;
    display: flex !important;
    justify-content: center !important;
}

.picmodal>button {
    margin-right: -57px;
    margin-top: -57px;
}



/* css for hovereff */

/* 
.container {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 40px 0;
}

.container .card {
    position: relative;
    width: 300px;
    height: 400px;
    background: rgba(255, 255, 255, 0.05);
    margin: 20px;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
}

.container .card .content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: 0.5s;
}

.container .card:hover .content {
    transform: translateY(-20px);
}

.container .card .content .imgBx {
    position: relative;
    width: 150px;
    height: 150px;
    overflow: hidden;
}

.container .card .content .imgBx img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
} */




/* //new */
.masonimage {
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.sci {
    position: absolute;
    /* bottom: 8px; */
    background-color: #3f0e77;
    flex-wrap: wrap;
    width: 100%;
    z-index: 3;

}



.masonimage .sci li {
    list-style: none;
    margin: 0 10px !important;
    transition: 0.5s;
    background-color: #3f0e77;
    width: 147px;
    display: flex;
    flex-direction: row;
    opacity: 1;
    display: none;
    height: 65px;
    align-items: center;


}

@media only screen and (max-width: 600px) {

    .sci.hover {
        display: none;
    }
    
    .sci{
        display: flex !important;
        flex-wrap: unset !important;
    }

    .css-1sexnva-MuiSvgIcon-root {
        margin-bottom: -3px !important;
    }

    .css-hbwkp5-MuiGrid-root {
        max-width: 100% !important;
    }

    .css-hbwkp5-MuiGrid-root button{
        width: 100% !important;
    }

    .css-1krj0ox-MuiButtonBase-root-MuiButton-root {
        width: 98% !important;
    }
}


.masonimage:hover .sci li {
    list-style: none;
    margin: 0 10px !important;
    transition: 0.5s;
    background-color: #3f0e77;
    width: 147px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    opacity: 1;
    z-index: 3;
    /* transform: translateY(-70px); */

}

@media only screen and (max-width: 600px) {

    .masonimage .sci li {
        list-style: none;
        transition: 0.5s;
        background-color: #3f0e77;
        height: 60px;
        justify-content: space-between;
        display: contents;
        flex-wrap: wrap;
        opacity: 1;
        z-index: 3;
        text-align: center;



    }
}

@media only screen and (min-width:1920px) {

    .masonimage:hover .sci li {
        list-style: none;
        margin: 0 10px !important;
        transition: 0.5s;
        background-color: #3f0e77;
        width: 30%;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        opacity: 1;
        z-index: 3;
        text-align: center;



    }
}


    
    .checkboxDiv{
        position: relative;
  width: auto;
  height: 100px;
  float: left;
  margin-left: 10px;
    }

    input[type="checkbox"]{
        width: 30px; /*Desired width*/
        height: 30px; /*Desired height*/
      }

    .checkbox {
        position: absolute;
        right: 5px;
        top: 5px;
        background-color: #000;
      }



@media (max-width: 390px) {
    /* .imageContainer{
      margin-left: 22px;
  } */
  .imgboxpic{
      /* width: 294px !important;
      height: 410px !important; */
      object-fit: contain;
  }
  .css-grmm1u-MuiButtonBase-root-MuiButton-root{
      margin-top: -20px !important;
      margin-right: -10px !important;
  }
}
@media (max-width: 500px) {
  /* .imageContainer{
      margin-left: 27px;
  } */

  .modalHeight{
    height:80rem;
    overflow-y:scroll;
  }

  .css-grmm1u-MuiButtonBase-root-MuiButton-root{
      margin-top: -20px !important;
      margin-right: -10px !important;
  }
  /* .imgboxpic{
      width: 338px !important;
      height: 410px !important;
      object-fit: cover;
  } */

  .css-1mj6m5z-MuiGrid-root {
      display: flex;
      justify-content: center;
  }

  .css-hbwkp5-MuiGrid-root {
      margin-bottom: 10px ;
  }

  .css-a5625r{
      width: 92vw !important;
  }

  .css-lec2qu-MuiGrid-root {
      margin-top: -1.5rem !important;
  }

  .css-ahj2mt-MuiTypography-root {
      margin-left: 10px !important;
  }

  .closeround{
      margin-top: -8px ;
  }

  .css-fwhstj {
      width: 93vw !important;
  }
  .css-11lq3yg-MuiGrid-root {
      margin-left: -10px;
  }

  .cardmodalnew {
      width: 156px !important;
  }
  .cardimgboxonenew {
      width: 117px !important;
  }
  .css-1nios73-MuiButtonBase-root-MuiButton-root {
      width: 130px !important;
  }
  /* modal collage  */
  /* .css-1hkylpt {
      width: 348px !important;
      margin-top: 14rem;
  }    */

  /* cart */
  .css-gnmkce marquee{
      margin-top: 3rem !important;
      padding: 15px 80px !important;
  }
}

@media (min-width: 501px) {
}
.css-1mj6m5z-MuiGrid-root {
  display: flex !important;
  flex-direction: row !important;
  grid-column-gap: 0 !important;
  column-gap: 0 !important;
  /* max-width: 48% !important; */
}

.carouselButton1{
  width: 100% !important;
}

.css-hbwkp5-MuiGrid-root {
  margin-bottom: 10px ;
}
@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 50px;
    height: 50px;
    animation: spinner 1.5s linear infinite;
  }
  
  .spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 350px;
  }
.css-qkwlfp {
    margin-top: 0px !important;
}

@media screen and (max-width: 500px) {
    #signinPage .css-181cdy9 {
      display: none;
    }

    #signinPage .css-1yezka2-MuiTypography-root{
        font-size: 21px !important;
    }

    #signinPage .css-bz1ix6{
        width: 94vw;
    }

    #signinPage .css-4513st{
        font-size: 1.3rem;
    font-family: 'Urbanist-Medium';
    }
    #signinPage .css-bz1ix6 {
        margin-top: 23px !important;
    }

    #signinPage .css-qkwlfp {
        margin-right: 13px;
    }
    #signinPage .css-lbbh85{
        display: flex;
        flex-direction: column;
        margin-top: 13px !important;
        margin-bottom: 13px !important;
    }

    #signinPage .css-bv6fd0-MuiButtonBase-root-MuiButton-root{
        width: 87vw !important;
        margin-bottom: 10px !important;
    }

    #signinPage .css-1vpstr-MuiTypography-root{
        width: 80vw;
    }

    #signinPage .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root{
        width: 87vw !important;
    }
    #signinPage .css-4nb6ss{
        display: flex;
        justify-content: flex-start !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        width: 87vw;
    }

    #signinPage .css-1jivpc7-MuiButtonBase-root-MuiButton-root{
        font-size: 1.8rem !important;
        padding: 1rem;
    }

    #signinPage .css-162b50h-MuiCardActions-root{
        width: 95vw !important;
    }
    #signinPage .css-qkwlfp {
        margin-top: 0px !important;
    }
  }

  @media screen and (min-width: 500px) and (max-width: 919px) {

    #signinPage .css-181cdy9{
        height: 105vh !important;
        object-fit: cover;

    }
 
    #signinPage .css-4513st{
        font-size: 20px;
    }

    #signinPage .css-1yezka2-MuiTypography-root{
        font-size: 25px !important;
    }

    #signinPage .css-bv6fd0-MuiButtonBase-root-MuiButton-root{
        margin-right: 1rem !important;
    }

    #signinPage .css-12keydw{
        padding-bottom: 40px !important;
    }

    #signinPage .css-lbbh85{
        display: flex;
        flex-direction: column;
       
    }

    .css-y4d4gl-MuiButtonBase-root-MuiButton-root,.css-1s44gg4-MuiButtonBase-root-MuiButton-root{
        min-width: 36vw !important;
    }

    #signinPage .css-12keydw{
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }

    #signinPage .css-bv6fd0-MuiButtonBase-root-MuiButton-root {
        margin-bottom: 15px !important;
    }
    #signinPage .css-qkwlfp {
        margin-top: 0px !important;
    }

  }

  @media screen and (max-width: 920px) {
    #signinPage .css-181cdy9{
        height: 71.5vh;
        object-fit: cover;

    }

    #signinPage .css-1gck903{
        margin-left: 10rem !important;
    }
    #signinPage .css-qkwlfp {
        margin-top: 0px !important;
    }


  }

  @media screen and (max-width: 1200px) {
    #signinPage .css-181cdy9{
        height: 148vh;
        object-fit: cover;

    }

    #signinPage .css-lbbh85{
        display: flex;
        flex-direction: column !important;
       
    }

    #signinPage .css-1gck903{
        margin-left: 13rem !important;
    }
    #signinPage .css-qkwlfp {
        margin-top: 0px !important;
    }


  }

  @media screen and (min-width: 1200px) {
     #signinPage .css-lbbh85{
        display: flex;
        flex-direction: row !important;
       
    }

    .css-qkwlfp {
        margin-top: 0px !important;
    }

    /* .css-1tb0f6m-MuiButtonBase-root-MuiButton-root{
        min-width: 18vw !important;
    } */
  }


@media screen and (max-width: 500px) {
    .css-181cdy9 {
      display: none;
    }

    .css-1yezka2-MuiTypography-root{
        font-size: 21px !important;
    }

    .css-bz1ix6{
        width: 94vw;
    }

    .css-4513st{
        font-size: 1.3rem;
        font-family: 'Urbanist-Medium';
    }
    .css-bz1ix6 {
        margin-top: 23px !important;
    }

     .css-qkwlfp {
        margin-right: 13px !important;
    }
    .css-lbbh85{
        display: flex;
        flex-direction: column;
        margin-top: 13px !important;
        margin-bottom: 13px !important;
    }

   .css-bv6fd0-MuiButtonBase-root-MuiButton-root{
        width: 87vw !important;
        margin-bottom: 10px !important;
    }

     .css-1vpstr-MuiTypography-root{
        width: 80vw;
    }

     /* .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root{
        width: 87vw !important;
    } */
    .css-4nb6ss{
        display: flex;
        justify-content: flex-start !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        width: 87vw;
    }

   .css-1jivpc7-MuiButtonBase-root-MuiButton-root{
        font-size: 1.8rem !important;
        padding: 1rem;
    }

     .css-162b50h-MuiCardActions-root{
        width: 94vw !important;
    }

    /* .css-1n2mv2k{
        width: 20rem !important;
    } */
    .css-16dv9uf-MuiButtonBase-root-MuiButton-root {
        margin-bottom: 10px !important;
    }

    .css-3khax1-MuiButtonBase-root-MuiButton-root{
        min-width: 84vw !important;
    }
    /* cart */
    /* .css-16to7xx{
        display: none !important;
    } */
  }

  @media screen and (min-width: 500px) and (max-width: 919px) {

     .css-181cdy9{
        height: 105vh !important;
        object-fit: cover;
    }
 
     .css-4513st{
        font-size: 20px;
    }
 .css-1yezka2-MuiTypography-root{
        font-size: 25px !important;
    }

    .css-bv6fd0-MuiButtonBase-root-MuiButton-root{
        margin-right: 1rem !important;
    }

     .css-12keydw{
        padding-bottom: 40px !important;
    }

     .css-lbbh85{
        display: flex;
        flex-direction: column;
       
    }

    .css-12keydw{
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .css-bv6fd0-MuiButtonBase-root-MuiButton-root {
        margin-bottom: 15px !important;
    }
    /* .css-1n2mv2k{
        width: 20rem !important;
    } */
    .css-16dv9uf-MuiButtonBase-root-MuiButton-root {
        margin-bottom: 10px !important;
    }
     /* cart */
     /* .css-16to7xx{
        display: none !important;
    } */
  }

  @media screen and (max-width: 920px) {
     .css-181cdy9{
        height: 71.5vh;
        object-fit: cover;

    }

     .css-1gck903{
        margin-left: 10rem !important;
    }

  }

  @media screen and (min-width: 921px) and (max-width: 1200px) {
     .css-181cdy9{
        height: 148vh;
        object-fit: cover;
    }

    /* #signinPage .css-lbbh85{
        display: flex;
        flex-direction: row !important;
       
    } */

     .css-1gck903{
        margin-left: 13rem !important;
    }

    .css-lbbh85 {
        flex-direction: column !important;
    }

    /* .css-1n2mv2k{
        width: 20rem !important;
    } */
    .css-16dv9uf-MuiButtonBase-root-MuiButton-root {
        margin-bottom: 10px !important;
    }

    /* .css-16to7xx{
        display: none !important;
    } */

  }

  @media screen and (min-width: 1200px) {
      .css-lbbh85{
        display: flex;
        flex-direction: column !important;
       
    }

    /* .css-1n2mv2k{
        width: 26rem !important;
    }
    .css-16dv9uf-MuiButtonBase-root-MuiButton-root {
        margin-bottom: 10px !important;
    } */
/*     
    .css-16to7xx{
        display: none !important;
    } */
  }
  .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 6.5px 14px !important;
  }
/* Slideshow */

.slideshow {
    margin: 0 auto;
    overflow: hidden;
    max-width: 1000px;
  }

  @media (max-width:500px){
    .slideshow{
        max-width: 57rem ;
    }
    .sliderImage{
        width: 61vw !important;
      }
  }
  
  .slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
    width: 13rem;
  }
  
  .slide {
    display: inline-block;
  
    /* height: 300px; */
    width: 100%;
    border-radius: 40px;
  }
  
  .sliderImage{
    object-fit: contain;
    width: 25vw;
  }
  .sliderImage2
  {
    object-fit: contain;
    width: 15vw;
  }
  /* Buttons */
  
  .slideshowDots {
    text-align: center;
  }
  
  .slideshowDot {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
  
    cursor: pointer;
    margin: 15px 7px 0px;
  
    background-color: #c4c4c4;
  }
  
  .slideshowDot.active {
    background-color: #6a0dad;
  }
  
@media (max-width:500px){
    .css-bapjqg {
        position: fixed;
        left: 0px;
        bottom: 0px;
        height: 69px;
        width: 100%;
    }
}
.inputField{
    height: 2.5rem;
    width: 100%;
    
}

form{position:relative;}
.tasksInput{margin-right:150px;}


.textareaField{
    height: 6rem;
    width: 100%;
    padding: 10px;
}

.formFieldError {
    border: 1px solid #e11d48;
  }
  
  .formFieldErrorMessage {
    display: flex;
    margin: 0 0 0 0;
    color: #e11d48;
  }
.LoginTabs > div {
    padding: 0;
}

.LoginTabs > div > p {
    margin: 0 !important;
}

.LoginTabs > div > p > form > div {
    padding-left: 0;
    padding-right: 0;
}

#UserOrderDetailsForm {
    padding-top: 2rem;
    padding-bottom: 2rem;
}



@media screen and (max-width: 678px) {
    #NotLoggedInCheckoutPage #SigninTab form > div {
        width: 92vw !important;
    }
}

