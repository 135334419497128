.inputField{
    height: 2.5rem;
    width: 100%;
    
}

form{position:relative;}
.tasksInput{margin-right:150px;}


.textareaField{
    height: 6rem;
    width: 100%;
    padding: 10px;
}

.formFieldError {
    border: 1px solid #e11d48;
  }
  
  .formFieldErrorMessage {
    display: flex;
    margin: 0 0 0 0;
    color: #e11d48;
  }