.css-1ndpvdd-MuiTableCell-root {
    color: #3F0E77 !important;

}


.css-quj9j6-MuiTable-root {
    background-color: #f2f6ff !important;

}

.css-1q1u3t4-MuiTableRow-root {

    background-color: #f4ebff;
}

.css-1lxrn4q {
    padding-top: 0% !important;
}


.leftgrid {
    display: flex;
    flex-direction: row;
}

.bgbox {
    width: 100%;
    height: auto;
    background: #F4EBFF;
    padding-bottom: 0% !important;
}


.imagecontent {
    margin: 10px;
}