@media (width <= 390px) {
    /* .imageContainer{
      margin-left: 22px;
  } */
  .imgboxpic{
      /* width: 294px !important;
      height: 410px !important; */
      object-fit: contain;
  }
  .css-grmm1u-MuiButtonBase-root-MuiButton-root{
      margin-top: -20px !important;
      margin-right: -10px !important;
  }
}
@media (width <= 500px) {
  /* .imageContainer{
      margin-left: 27px;
  } */

  .modalHeight{
    height:80rem;
    overflow-y:scroll;
  }

  .css-grmm1u-MuiButtonBase-root-MuiButton-root{
      margin-top: -20px !important;
      margin-right: -10px !important;
  }
  /* .imgboxpic{
      width: 338px !important;
      height: 410px !important;
      object-fit: cover;
  } */

  .css-1mj6m5z-MuiGrid-root {
      display: flex;
      justify-content: center;
  }

  .css-hbwkp5-MuiGrid-root {
      margin-bottom: 10px ;
  }

  .css-a5625r{
      width: 92vw !important;
  }

  .css-lec2qu-MuiGrid-root {
      margin-top: -1.5rem !important;
  }

  .css-ahj2mt-MuiTypography-root {
      margin-left: 10px !important;
  }

  .closeround{
      margin-top: -8px ;
  }

  .css-fwhstj {
      width: 93vw !important;
  }
  .css-11lq3yg-MuiGrid-root {
      margin-left: -10px;
  }

  .cardmodalnew {
      width: 156px !important;
  }
  .cardimgboxonenew {
      width: 117px !important;
  }
  .css-1nios73-MuiButtonBase-root-MuiButton-root {
      width: 130px !important;
  }
  /* modal collage  */
  /* .css-1hkylpt {
      width: 348px !important;
      margin-top: 14rem;
  }    */

  /* cart */
  .css-gnmkce marquee{
      margin-top: 3rem !important;
      padding: 15px 80px !important;
  }
}

@media (width >= 501px) {
}
.css-1mj6m5z-MuiGrid-root {
  display: flex !important;
  flex-direction: row !important;
  column-gap: 0 !important;
  /* max-width: 48% !important; */
}

.carouselButton1{
  width: 100% !important;
}

.css-hbwkp5-MuiGrid-root {
  margin-bottom: 10px ;
}