@font-face {
  font-family: "Urbanist-Black";
  src: url("../src/Assets/Fonts/Urbanist/Urbanist-Black.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Urbanist-BlackItalic";
  src: url("../src/Assets/Fonts/Urbanist/Urbanist-BlackItalic.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Urbanist-SemiBold";
  src: url("../src/Assets/Fonts/Urbanist/Urbanist-SemiBold.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Urbanist-Bold";
  src: url("../src/Assets/Fonts/Urbanist/Urbanist-Bold.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Urbanist-BoldItalic";
  src: url("../src/Assets/Fonts/Urbanist/Urbanist-BoldItalic.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Urbanist-ExtraBold";
  src: url("../src/Assets/Fonts/Urbanist/Urbanist-ExtraBold.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Urbanist-ExtraBoldItalic";
  src: url("../src/Assets/Fonts/Urbanist/Urbanist-ExtraBoldItalic.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Urbanist-Italic";
  src: url("../src/Assets/Fonts/Urbanist/Urbanist-Italic.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Urbanist-Light";
  src: url("../src/Assets/Fonts/Urbanist/Urbanist-Light.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Urbanist-LightItalic";
  src: url("../src/Assets/Fonts/Urbanist/Urbanist-LightItalic.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Urbanist-Medium";
  src: url("../src/Assets/Fonts/Urbanist/Urbanist-Medium.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Urbanist-MediumItalic";
  src: url("../src/Assets/Fonts/Urbanist/Urbanist-MediumItalic.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Urbanist-Regular";
  src: url("../src/Assets/Fonts/Urbanist/Urbanist-Regular.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Urbanist-Thin";
  src: url("../src/Assets/Fonts/Urbanist/Urbanist-Thin.ttf");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Urbanist-ThinItalic";
  src: url("../src/Assets/Fonts/Urbanist/Urbanist-ThinItalic.ttf");
  font-style: normal;
  font-display: block;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Urbanist-Regular', 'Urbanist-Thin', 'Urbanist-Medium',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: '0px !important';

}

a {
  text-decoration: none;
}

/* Will Make Header Sticky on Scroll */
.is-sticky {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  z-index: 999 !important;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%) !important;
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

/* .oneGlint-link-title {
  color: red;
} */


.oneGlint-active-link-title {
  color: #ddc970 !important;
}

.oneGlint-active-link-title::after {
  content: '';
  width: 100%;
  height: 5px;
  background-color: #ddc970;
}

.user-menu-list:last-child {
  border: none;
}

/* css-1te3qol-MuiGrid-root */

@media (min-width: 1800px) {
  .css-1te3qol-MuiGrid-root li {
    width: 8rem !important;
  }
}


.MuiCheckbox-colorPrimary.Mui-checked {
  color: #3f0e77 !important;
}

@media(max-width: 500px) {

  /* custom navbar */
  .css-l9him9-MuiPaper-root-MuiAppBar-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }



  /* summary section */
  .css-1aeewgg-MuiTypography-root {
    padding-right: 42px !important;
    padding-left: 48px !important;
    border-bottom: 1px solid lightgrey;
    width: 124% !important;
    margin-bottom: 13px !important;
  }

  .css-1aeewgg-MuiTypography-root span {
    margin-bottom: 12px;
  }

  .premiumModal {
    height: 30%;
    overflow-x: scroll;
  }
}

.css-mhc70k-MuiGrid-root {
  display: flex;
  justify-content: center;
}

.css-1jssz31-MuiGrid-root {
  flex-basis: 0% !important;
}

.css-o92l7v-MuiButtonBase-root-MuiButton-root {
  margin-right: 85px !important;
}

.css-182didf.MuiSelect-select {
  display: flex !important;
  align-items: center !important;
  height: "2.4rem" !important;
}

.collageWarning {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 4px;

  width: 415px;
  height: 48px;


  background: #FEE4E2;
  border-radius: 8px;
  margin-bottom: 15px;
}

.collageWarningText {
  color: #F04438;
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 100vw;
}

/**custom css 06.06.23**/
/* @media(max-width:899px){ */
/* .css-1e9f679, .css-iarih6-MuiGrid-root{ */
/* .css-1e9f679 .web_view{
    display: none !important;
  }
} */
@media(max-width:428px) {
  .css-basgfo {
    padding: 10px 50px !important;
    height: auto !important;
  }
}

@media (max-width:500px) {
  .css-ugb9rx {
    width: 350px !important
  }
}

.css-1e9f679 {
  background-color: #e4e4e4 !important;
}

.css-qkwlfp svg {
  vertical-align: middle;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 6px 14px !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: -7px !important;
}



/**For Loader**/
.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
  left: 48%;
  margin-top: 15%;
}

.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #4f0070;
  animation: prixClipFix 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg)
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
  }

  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%)
  }

  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0)
  }
}


/**Today 29.08.23**/
.EventRegdOl {
  padding: 10px 10px 10px 20px;
}

.EventRegdOl li {
  padding-bottom: 10px;
}

.termCondition {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
}


.container {
  width: 80%;
  margin: 50px auto;
}

.button {
  background: #2196f3;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
}

.button:hover {
  background: #0b7dda;
}

/* Style the modal */
.modal {
  position: fixed;
  /* Stay in place */
  z-index: 9999;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  background-color: rgba(0, 0, 0, 0.4);
  /* Semi-transparent black background */

}

/* Modal Content */
.modal-content {
  margin: auto;
  padding: 20px;
  display: flex;
  align-items: start;
}

.modal-text {
  font-size: 18px;
  color: #ccc;
}

/* Create the circle loader with pure CSS */
.loader {
  width: 50px;
  height: 50px;
  border: 8px solid #ddc970;
  border-top: 8px solid #3f0e77;
  border-bottom: 8px solid #3f0e77;
  border-radius: 50%;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.datepicker_custom input {
  width: 100%;
  /* height: 1.4375em; */
  border-radius: 3px;
  border: 1px solid #ccc;
  padding: 10px 14px;
}

.datepicker_custom .react-datepicker-wrapper {
  display: block;
}

.container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checkbox-input {
  margin-right: 10px;
}

.checkbox-label {
  font-size: 16px;
  cursor: pointer;
}

.checkbox-label:hover {
  text-decoration: underline;
}



/* .fullWidthContainer{
  max-width: 1150px !important;
  width: 100% !important;
  margin: 0 auto !important;
  padding: 0 20px;
} */

/* .fullWidthContainer .custom_table th{
  padding: 10px;
  width: 60% !important;
  line-height: 10;
} */

/**MediaQuery**/
@media(max-width:1300px) {}

@media(max-width:575px) {
  .evntRegd {
    margin-left: 10px !important;
  }
}

@media (max-width:599px) {
  .GridpaddingRight {
    padding-right: 0 !important;
  }
}

/* pagination */
@media screen and (max-width: 375px) {
  .css-jx5j6l .MuiPagination-ul {
    gap: 7px !important;
  }
}

@media screen and (min-width: 375px) {
  .css-jx5j6l .MuiPagination-ul {
    gap: 12px !important;
  }
}



.css-jx5j6l .MuiPagination-ul {
  margin: 16px 4px !important;
}

.dropdown {
  position: relative;
  display: inline-block;

}

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  padding: 10px;
  z-index: 9;
  max-Height: 200px;
  overflow-y: auto
}

/* .dropdown:hover .dropdown-content {
  display: block;
} */

/* Additional styling for the input and card content */
#myInput {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.card {
  padding: 10px;
  background-color: white;
  border-radius: 4px;
  margin-top: 5px;
  cursor: pointer;
}