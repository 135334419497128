img {
  max-width: 100%;
  display: block;
}

figure {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 10px;
  break-inside: avoid;
}

figure > img {
  grid-row: 1 / -1;
  grid-column: 1;
}

figure a {
  color: black;
  text-decoration: none;
}

figure:hover{
  /* display: block; */
  cursor:pointer;
}


figcaption {
  grid-row: 2;
  grid-column: 1;
  /* background-color: rgba(255,255,255,.5); */
  cursor: pointer;
  padding: .2em .5em;
  justify-self: center;
  margin-bottom: 2rem;
}

/* .hide{
  display: none;
}
figure:hover + .hide{
  display: flex;
  border: 5px solid red;
} */



.container {
  column-count: 4;
  column-gap: 10px;
}

/* .container:before {
content:"";
position:absolute;
width:100%;
height:100%;
top:0;left:0;right:0;
background-color:rgba(0,0,0,0);
} */
/* .container:hover::before {
background-color:rgba(0,0,0,0.5);
} */
/* .container img {
display:block;
}
.container button {
position: absolute;
top: 60%;
left: 50%;
transform: translate(-50%, -50%);
-ms-transform: translate(-50%, -50%);
opacity:0;
}  */
/* .container:hover button {   
opacity: 1;
} */

@media(max-width: 500px){

.container {
width: 72% !important;
margin-left: 56px !important;
column-count: auto !important;
column-gap: 0px !important;
}
}