/* Slideshow */

.slideshow {
    margin: 0 auto;
    overflow: hidden;
    max-width: 1000px;
  }

  @media (max-width:500px){
    .slideshow{
        max-width: 57rem ;
    }
    .sliderImage{
        width: 61vw !important;
      }
  }
  
  .slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
    width: 13rem;
  }
  
  .slide {
    display: inline-block;
  
    /* height: 300px; */
    width: 100%;
    border-radius: 40px;
  }
  
  .sliderImage{
    object-fit: contain;
    width: 25vw;
  }
  .sliderImage2
  {
    object-fit: contain;
    width: 15vw;
  }
  /* Buttons */
  
  .slideshowDots {
    text-align: center;
  }
  
  .slideshowDot {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
  
    cursor: pointer;
    margin: 15px 7px 0px;
  
    background-color: #c4c4c4;
  }
  
  .slideshowDot.active {
    background-color: #6a0dad;
  }
  