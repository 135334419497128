@media(max-width:600px) {
    #combo-box-demo {
        color: white;
        font-family: 'Urbanist-Regular';
        height: 2.5rem;
        width: 100%;
        font-size: 1.5rem;
    }

    #afterSubmit {
        color: black;
        font-family: 'Urbanist-Regular';
        height: 2.5rem;
        font-size: 1.3rem;
    }

    #combo-box-demo::placeholder {
        color: white
    }
}

@media(max-width:1546px) {
    #combo-box-demo {
        color: white;
        font-family: 'Urbanist-Regular';
        height: 2.5rem;
        width: 100%;
        font-size: 16px;
    }

    #afterSubmit {
        color: black;
        font-family: 'Urbanist-Regular';
        /* height: 2.5rem; */
        font-size: 16px;
    }

    #combo-box-demo::placeholder {
        color: white
    }
}

@media (max-width:1900px) {
    #combo-box-demo {
        color: white;
        font-family: 'Urbanist-Regular';
        height: 40px;
    }

    #afterSubmit {
        color: black;
        font-family: 'Urbanist-Regular';
    }

    #combo-box-demo::placeholder {
        color: white
    }
}

.MuiAutocomplete-root {
    border-radius: 5px;
}

#afterSubmit {
    color: black;
}