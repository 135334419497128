.css-qkwlfp {
    margin-top: 0px !important;
}

@media screen and (max-width: 500px) {
    #signinPage .css-181cdy9 {
      display: none;
    }

    #signinPage .css-1yezka2-MuiTypography-root{
        font-size: 21px !important;
    }

    #signinPage .css-bz1ix6{
        width: 94vw;
    }

    #signinPage .css-4513st{
        font-size: 1.3rem;
    font-family: 'Urbanist-Medium';
    }
    #signinPage .css-bz1ix6 {
        margin-top: 23px !important;
    }

    #signinPage .css-qkwlfp {
        margin-right: 13px;
    }
    #signinPage .css-lbbh85{
        display: flex;
        flex-direction: column;
        margin-top: 13px !important;
        margin-bottom: 13px !important;
    }

    #signinPage .css-bv6fd0-MuiButtonBase-root-MuiButton-root{
        width: 87vw !important;
        margin-bottom: 10px !important;
    }

    #signinPage .css-1vpstr-MuiTypography-root{
        width: 80vw;
    }

    #signinPage .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root{
        width: 87vw !important;
    }
    #signinPage .css-4nb6ss{
        display: flex;
        justify-content: flex-start !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        width: 87vw;
    }

    #signinPage .css-1jivpc7-MuiButtonBase-root-MuiButton-root{
        font-size: 1.8rem !important;
        padding: 1rem;
    }

    #signinPage .css-162b50h-MuiCardActions-root{
        width: 95vw !important;
    }
    #signinPage .css-qkwlfp {
        margin-top: 0px !important;
    }
  }

  @media screen and (min-width: 500px) and (max-width: 919px) {

    #signinPage .css-181cdy9{
        height: 105vh !important;
        object-fit: cover;

    }
 
    #signinPage .css-4513st{
        font-size: 20px;
    }

    #signinPage .css-1yezka2-MuiTypography-root{
        font-size: 25px !important;
    }

    #signinPage .css-bv6fd0-MuiButtonBase-root-MuiButton-root{
        margin-right: 1rem !important;
    }

    #signinPage .css-12keydw{
        padding-bottom: 40px !important;
    }

    #signinPage .css-lbbh85{
        display: flex;
        flex-direction: column;
       
    }

    .css-y4d4gl-MuiButtonBase-root-MuiButton-root,.css-1s44gg4-MuiButtonBase-root-MuiButton-root{
        min-width: 36vw !important;
    }

    #signinPage .css-12keydw{
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }

    #signinPage .css-bv6fd0-MuiButtonBase-root-MuiButton-root {
        margin-bottom: 15px !important;
    }
    #signinPage .css-qkwlfp {
        margin-top: 0px !important;
    }

  }

  @media screen and (max-width: 920px) {
    #signinPage .css-181cdy9{
        height: 71.5vh;
        object-fit: cover;

    }

    #signinPage .css-1gck903{
        margin-left: 10rem !important;
    }
    #signinPage .css-qkwlfp {
        margin-top: 0px !important;
    }


  }

  @media screen and (max-width: 1200px) {
    #signinPage .css-181cdy9{
        height: 148vh;
        object-fit: cover;

    }

    #signinPage .css-lbbh85{
        display: flex;
        flex-direction: column !important;
       
    }

    #signinPage .css-1gck903{
        margin-left: 13rem !important;
    }
    #signinPage .css-qkwlfp {
        margin-top: 0px !important;
    }


  }

  @media screen and (min-width: 1200px) {
     #signinPage .css-lbbh85{
        display: flex;
        flex-direction: row !important;
       
    }

    .css-qkwlfp {
        margin-top: 0px !important;
    }

    /* .css-1tb0f6m-MuiButtonBase-root-MuiButton-root{
        min-width: 18vw !important;
    } */
  }

