@import url("https://rsms.me/inter/inter.css");
:root {
  --color-light: white;
  --color-dark: #000000;
  --color-signal: #0B4AAF   ;
  --color-background: var(--color-light);
  --color-text: var(--color-dark);
  --color-accent: var(--color-signal);
  --size-bezel: .5rem;
  --size-radius: 4px;
  line-height: 1.4;
  font-family: "Inter", sans-serif;
  font-size: calc(0.6rem + 0.4vw);
  color: var(--color-text);
  background: var(--color-background);
} 

.input {
  position: relative;
}
.input__field {
  margin-top: 0.4rem;
  box-sizing: border-box;
  display: block;
  width: 100%;
  border: 1px solid currentColor;
  padding: calc(var(--size-bezel) * 1.5) var(--size-bezel);
  color: currentColor;
  background: transparent;
  border-radius: var(--size-radius);
}
.input__field:not(:-moz-placeholder-shown) + .input__label {
  color: var(--color-accent);
}
.input__field:not(:-ms-input-placeholder) + .input__label {
  color: var(--color-accent);
}
.input__field:focus + .input__label, .input__field:not(:placeholder-shown) + .input__label {
  color: var(--color-accent);
}

.hidden {
  display: none;
}

.modalDiv{
  overflow-x: hidden !important;
}