/* Less than or equal to 500px */
@media (width <= 500px) {
    /* Modal */
    .css-10je69f {
        width: 245px !important;
    }

    .css-cohsu9 {
        width: 15vw !important;
    }

    .css-1lmnfor {
        display: flex;
        flex-wrap: unset !important;
        gap: 0 !important;
        margin-bottom: 20px;
    }
    
    .css-rcwncx {
        font-size: 13px !important;
    }

    .css-19f6m5p-MuiButtonBase-root-MuiButton-root {
        padding-top: 12px !important;
        padding-bottom: 12px !important;
        font-size: 13px !important;
    }

    .css-1rfvejp-MuiTypography-root{
        display: flex;
        justify-content: center !important;
    }

    .css-15dpfag{
        display: block !important;
    }

    /* .css-12rf6b2 {
        display: block !important;
    } */
    .css-12rf6b2:after {
       content:"Checkout";
       font-size: 14px;
    }
}