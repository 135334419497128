.LoginTabs > div {
    padding: 0;
}

.LoginTabs > div > p {
    margin: 0 !important;
}

.LoginTabs > div > p > form > div {
    padding-left: 0;
    padding-right: 0;
}

#UserOrderDetailsForm {
    padding-top: 2rem;
    padding-bottom: 2rem;
}



@media screen and (max-width: 678px) {
    #NotLoggedInCheckoutPage #SigninTab form > div {
        width: 92vw !important;
    }
}
