.my-masonry-grid {
    display: -webkit-box;
    /* Not needed if autoprefixing */
    display: -ms-flexbox;
    /* Not needed if autoprefixing */
    display: flex;
    margin-left: -8px;
    /*gutter size offset*/
    width: auto;
    gap: 10px;
}

.my-masonry-grid_column {
    padding-left: 8px;
    /* gutter size */
    background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column>div {
    /* change div to reference your elements you put in <Masonry> */
    /* background: grey; */
    margin-bottom: 8px;

}



.overlay {
    max-width: "19rem";
    height: "12rem";
    position: "relative";
    top: '50%';
    background:
        "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,212,255,0) 75%, rgba(0,212,255,0) 100%)";
    opacity: 0;
}




.closeround {
    background-color: red;
    border-radius: 50%;
    color: #fff;
    height: 18px;
    width: 18px;
}

.muimodalfirst {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.cardmodal {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: solid 1px #D1D5DB;
    width: 220px;
    border-radius: 3px;
    z-index: 3px;
    margin: 10px;
    padding: 10px;
}

@media only screen and (max-width: 600px) {
    .cardmodal {
        border: solid 1px #D1D5DB;
        width: 156px;
        border-radius: 3px;
        z-index: 3px;
        margin: 10px;
        padding: 10px;
    }

}

.cardimgboxone {
    width: 100%;
    object-fit: cover;
    height: 107px;
    border-radius: 3px;
}

.css-gnmkce marquee{
    margin-top: 0rem;
}

/* product modal */


.css-1t3ij9a-MuiGrid-root{
    display: flex;
    justify-content: center;
    align-items: center;
}


/* .css-mhc70k-MuiGrid-root {
    justify-content: center !important;
} */

/* .css-1jssz31-MuiGrid-root {
    -webkit-flex-basis: 0% !important;
} */

/* .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    margin-top: -45px !important;
    margin-right: -41px !important;
} */

.picmodal>button{

    margin-top: -57px !important;
        margin-right: -57px !important;
}


@media only screen and (max-width: 600px) {
    .cardimgboxone {
        width: 100%;
        height: 55px;
        border-radius: 3px;
    }

    .imgboxpic {
        width: 100% !important;
        /* margin-left: 22px !important; */
        object-fit: cover;
    }
}
.cartButton{
    width: 150px !important;
}

.buttonContainer{
    display: flex !important;
    flex-direction: row !important;
    column-gap: 0 !important;
}
.smallname {
    font-size: 14px;
    color: #6B7280;
}

.pricename {
    color: #000;
    font-weight: bolder;
}

.buttonsmodal {
    background-color: #E5E7EB;
    margin-top: 10px !important;
    padding: 25px;
    padding-right: 38px;
    transform: translateX(12px);
    border-radius: 3px;

}

.picmodal {
    border: 1px solid #E5E7EB;
    border-radius: 5px;
}


.imgboxpic {
    height: 24rem;
    width: 100%;
    object-fit: contain;
    /* padding: 21px; */
    border-radius: 5px;
    position: relative;
    background-repeat: no-repeat;
    background-size: contain;
}

.react-multiple-carousel__arrow {
    min-height: 30px;
    min-width: 30px;
    /* margin-bottom: 10rem; */
}

.css-a5625r,.css-fwhstj {
    /* height: 93vh; */
    width: 39vw !important;
}

/* .css-1hkylpt {
    height: 93vh;
} */

@media only screen and (max-width: 600px) {

    .react-multiple-carousel__arrow {
        display: none;
    }
}

.react-multiple-carousel__arrow--left {

    background-color: #3f0e77;
    font-size: 13px !important;

}

.react-multiple-carousel__arrow--right {

    background-color: #3f0e77;
    font-size: 13px !important;


}


.closeroundnew {
    background-color: red;
    right: 0% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
    border-radius: 50%;
    color: #fff;
    height: 18px;
    width: 18px;
}

.picmodal>button{
    margin-right: -57px !important;
    margin-top: -57px !important;
}

@media only screen and (max-width: 600px) {
    .closeroundnew {
        height: 13px;
        width: 13px;
    }
}


.pickclickbox {
    display: flex;
    justify-content: space-around;
}

.leftpickpara {
    padding: 10px;
    line-height: 16px !important;
    font-size: 0.8rem !important;
    width: 100%;
    color: #6B7280;
    margin-left: 20px !important;
    padding-right: 40px !important;
}

.cardmodalnew {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: solid 1px #D1D5DB;
    width: 90%;
    border-radius: 3px;
    z-index: 3px;
    margin: 10px;
    padding: 10px;
}


.cardimgboxonenew {
    width: 100%;
    height: 107px;
    border-radius: 3px;
    object-fit: contain;
}

.css-1nios73-MuiButtonBase-root-MuiButton-root {
    width: 156px !important;
}

.buttonsmodalnew {
    background-color: #E5E7EB;
    margin-top: 5px !important;
    padding: 10px;
    padding-right: px;
    transform: translateX(12px);
    border-radius: 3px;
    display: flex !important;
    justify-content: center !important;
}

.picmodal>button {
    margin-right: -57px;
    margin-top: -57px;
}



/* css for hovereff */

/* 
.container {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 40px 0;
}

.container .card {
    position: relative;
    width: 300px;
    height: 400px;
    background: rgba(255, 255, 255, 0.05);
    margin: 20px;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
}

.container .card .content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: 0.5s;
}

.container .card:hover .content {
    transform: translateY(-20px);
}

.container .card .content .imgBx {
    position: relative;
    width: 150px;
    height: 150px;
    overflow: hidden;
}

.container .card .content .imgBx img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
} */




/* //new */
.masonimage {
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.sci {
    position: absolute;
    /* bottom: 8px; */
    background-color: #3f0e77;
    flex-wrap: wrap;
    width: 100%;
    z-index: 3;

}



.masonimage .sci li {
    list-style: none;
    margin: 0 10px !important;
    transition: 0.5s;
    background-color: #3f0e77;
    width: 147px;
    display: flex;
    flex-direction: row;
    opacity: 1;
    display: none;
    height: 65px;
    align-items: center;


}

@media only screen and (max-width: 600px) {

    .sci.hover {
        display: none;
    }
    
    .sci{
        display: flex !important;
        flex-wrap: unset !important;
    }

    .css-1sexnva-MuiSvgIcon-root {
        margin-bottom: -3px !important;
    }

    .css-hbwkp5-MuiGrid-root {
        max-width: 100% !important;
    }

    .css-hbwkp5-MuiGrid-root button{
        width: 100% !important;
    }

    .css-1krj0ox-MuiButtonBase-root-MuiButton-root {
        width: 98% !important;
    }
}


.masonimage:hover .sci li {
    list-style: none;
    margin: 0 10px !important;
    transition: 0.5s;
    background-color: #3f0e77;
    width: 147px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    opacity: 1;
    z-index: 3;
    /* transform: translateY(-70px); */

}

@media only screen and (max-width: 600px) {

    .masonimage .sci li {
        list-style: none;
        transition: 0.5s;
        background-color: #3f0e77;
        height: 60px;
        justify-content: space-between;
        display: contents;
        flex-wrap: wrap;
        opacity: 1;
        z-index: 3;
        text-align: center;



    }
}

@media only screen and (min-width:1920px) {

    .masonimage:hover .sci li {
        list-style: none;
        margin: 0 10px !important;
        transition: 0.5s;
        background-color: #3f0e77;
        width: 30%;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        opacity: 1;
        z-index: 3;
        text-align: center;



    }
}


    
    .checkboxDiv{
        position: relative;
  width: auto;
  height: 100px;
  float: left;
  margin-left: 10px;
    }

    input[type="checkbox"]{
        width: 30px; /*Desired width*/
        height: 30px; /*Desired height*/
      }

    .checkbox {
        position: absolute;
        right: 5px;
        top: 5px;
        background-color: #000;
      }


