.newbox {
    background-color: #3F0E77;
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.lefttext {
    color: #ffffff;
}

.advancedSearchField{
font-style: normal;
font-weight: 500;
font-size: 14px;
width: 14rem;
color: #D0D5DD;
}
.advancedSearchField:hover{
    border: none;
}
.thead {
    color: #3F0E77 !important;
    font-size: 16px !important;
    font-weight: bold !important;
}

.tablerow {
    background-color: #ffffff !important;
}

.newboxab {
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.newboxc {
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}